.header {
  position: sticky;
  top: 70px;
  padding: 10px 16px;
  /*background: #008080;
  box-shadow: 0 0 4px #003c3c;*/
  background: #fc5b00;
  box-shadow: 0 0 4px #b94100;
  color: azure;
  z-index: 999;
}

.logosInText {
  height: 20px;
  padding-left: 5px;
  width: auto;
}

.OLKarte {
  width: 80%;
  height: auto;
}

.content {
  padding: 10px;
}

.subheading {
  padding: 10px 16px;
  background: #fc5b00;
  color: azure;
}

.sponsors {
  max-height: 75px;
  width: auto;
  padding: 5px;
  margin: auto;
  max-width: 220px;
  display: block;
}

.mainSponsors {
  max-width: 100%;
  display: grid;
  grid-template-columns: 80% 1fr;
}
@media only screen and (max-width: 768px) {
  .mainSponsors {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }

  .slide {
    width: 100%;
  }

  .card {
    width: 100%;
  }
  .card-img-top {
    max-height: 180px;
    width: auto;
    display: block;
    margin: auto;
    position: left;
  }
  .card-body {
    text-align: center;
  }
}

@media only screen and (max-width: 365px) {
  .sponsors {
    display: block;
    height: auto;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
  }
}

.allSponsors {
  margin: 10px;
}

.column {
  float: left;
  width: 50%;
  padding: 5px;
}

@media only screen and (max-width: 600px) {
  .column {
    float: left;
    width: 100%;
    padding: 5px;
  }
  .centered {
    font-size: 33px !important;
  }
}
.row {
  margin-bottom: 15px;
}

.pointer:hover {
  cursor: zoom-in;
}

.imgModal {
  width: 100%;
  height: 100%;
}

.olMap {
  margin-bottom: 10px;
}

.titleContainer {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 63px;
  text-transform: uppercase;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  letter-spacing: 1px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
}

@media only screen and (max-width: 835px) {
  .centered {
    font-size: 3px;
    -webkit-text-stroke-width: 1px;
  }
}
