.Homepicture {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: auto;
}

.sliderText {
  padding: 50px;
}

.sliderText p {
  text-align: left;
}

.sliderText h3 {
  border-bottom: 1px;
}

.sliderImage {
  max-width: 100%;
  max-height: 250px;
  display: block;
  margin: auto;
}

.raiffeisenLogo {
  max-width: 100%;
  max-height: 250px;
  display: block;
  margin-top: 20%;
}

.carousel-indicators li {
  color: black;
  box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.carousel {
  background-color: beige;
}
.main {
  padding-top: 70px;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
}
footer {
  text-align: center;
}

.row {
  --bs-gutter-x: 0;
}

.Ausschreibung {
  margin-right: 5px;
}