.navbar {
  color: #fc5b00;
  font-weight: bold;
  /*font-size: large;*/
}
.nav-link {
  color: #fc5b00 !important;
}
.test {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f8f9fa;
  z-index: 1000;
  height: 55px;
}
nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f8f9fa;
  z-index: 1000;
  height: 70px;
}

.btnNav {
  display: none;
  position: absolute;
  left: 15px;
  top: 10px;
  color: black;
  font-size: 20px;
  border: none;
  background-color: #f8f9fa;
}

.navLogo {
  position: absolute;
  right: 30px;
  height: 70px;
  top: 0;
}
@media screen and (max-width: 500px) {
  .list {
    flex-direction: column;
    height: auto;
  }
  .nav-item:nth-child(1) {
    border-top: 1px solid black;
    margin-top: 70px;
  }
  .nav-item {
    width: 100%;
    border-top: 1px solid black;
    text-align: center;
    margin-right: 0px;
  }
  .btnNav {
    display: block;
  }
  .navLogo {
    object-fit: cover;
  }
  .nav {
    background-color: #f8f9fa;
    width: 100%;
  }
  ul {
    border: 1px solid #ffffff;

    /* Border radius for Chrome, Webkit and other good browsers */
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -border-radius: 10px;
    border-radius: 10px;
  }

  /* Only add border to bottom of <li> */
  li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  /* Get rid of the last <li>'s bottom border to stop overlap with <ul>'s border */
  /* :last-child works in IE7+ */
  li:last-child {
    border-bottom: none;
  }
  li:first-child {
    border-top: none;
  }
}
